@tailwind base;
@tailwind components;
@tailwind utilities;

.terms * {
  all: revert;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {  
  input[type="range"]::-webkit-slider-thumb {
    width: 32px;
    -webkit-appearance: none;
    appearance: none;
    height: 32px;
    cursor: ew-resize;
    background-color: #ffffff;
    border: 4px solid #BC3710;
    border-radius: 50%;
    /* margin-top: -12px; */
  }

  /* input[type="range"]::-moz-range-thumb {
    width: 32px;
    -webkit-appearance: none;
    appearance: none;
    height: 32px;
    cursor: ew-resize;
    background-color: #ffffff;
    border: 4px solid #BC3710;
    border-radius: 50%;
  } */

  /* input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background-color: #BC3710;
    border: none;
    border-radius: 4px;
  } */

  /* input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background-color: #BC3710;
    border-radius: 4px;
  }
  
  input[type=range]::-ms-fill-upper {
    background-color: #BC3710;
    border-radius: 4px;
  } */

  /* input[type="range"]:focus::-webkit-slider-runnable-track {
    background-color: #BC3710;
  } */
}
